<script setup>
const { t } = useI18n()

const isOpen = ref(false)

useColorMode()

onKeyStroke('Escape', (e) => {
  e.preventDefault()
  isOpen.value = false
})
function closeMenu() {
  isOpen.value = false
}
function openMenu() {
  isOpen.value = true
}
</script>

<template>
  <div class="navigation-header">
    <div class="title-header">
      <MainLogo />
    </div>

    <div class="menu-button-wrapper">
      <NuxtLink
        :to="localePath({ name: 'search' })"
        class="menu-item menu-item-search"
      >
        {{ $t('navigation.searchTitle') }}
      </NuxtLink>

      <button
        :title="t('navigation.openNavigation')"
        class="menu-item"
        @click="openMenu()"
      >
        {{ $t('navigation.menuTitle') }}
      </button>
    </div>
  </div>

  <TheMainMenu :is-open="isOpen" :close-menu="closeMenu" />
</template>

<style lang="scss" scoped>
.navigation-header {
  width: 100%;
  height: var(--nav-header-height);
  padding: 0 var(--layout-margin-h);
  background-color: var(--color-background);
  border-bottom: var(--border-layout);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  line-height: 1;
  gap: 0;
  padding-top: 0.2rem;
}

.title-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
}

.menu-button-wrapper {
  display: flex;
  align-items: center;
  gap: 0.6em;
  @include media('>=phone') {
    gap: 1.5em;
  }
}

.menu-item {
  position: relative;
  display: flex;
  align-items: center;
  @extend %font-navigation;

  &:focus-visible {
    @extend %focus-visual;
    text-decoration: none;
  }
}

@include media('<372px') {
  .menu-item-search {
    display: none;
  }
}

@include media('>=tablet') {
  .menu-item {
    font-size: 2rem;
  }

  .navigation-header {
    align-items: center;
    gap: 1em;
    line-height: unset;
    flex-wrap: unset;
    padding-top: 0.2rem;
  }
}
</style>
