<script setup>
/* === i18n === */
const { t, locale } = useI18n()

/* === init === */
const globalContentStore = useGlobalContentStore()
const { menuLinks } = storeToRefs(globalContentStore)

defineProps({
  closeMenu: {
    type: Function,
    required: true,
  },
  isOpen: {
    type: Boolean,
  },
})
</script>

<template>
  <ShadowPlane
    :is-active="isOpen"
    @click="
      () => {
        closeMenu()
      }
    "
  />

  <Transition name="menu">
    <div v-if="isOpen" class="menu">
      <div class="header">
        <div class="lang-widget-wrapper">
          <TheLanguageWidget />
        </div>
        <button
          class="close-button"
          :title="t('navigation.closeNavigation')"
          @click="closeMenu"
        >
          {{ $t('navigation.closeNavigationButton') }}
        </button>
      </div>
      <nav class="main-navigation">
        <ul class="main-navigation-list">
          <li
            v-for="link of menuLinks"
            :key="link.url"
            :class="{ 'space-after': link.space_after }"
          >
            <NuxtLink :to="link.url" @click="closeMenu">{{
              link.label
            }}</NuxtLink>
          </li>
        </ul>
      </nav>
      <div class="color-scheme-widget-wrapper">
        <TheColorSchemeWidget />
      </div>
    </div>
  </Transition>
</template>

<style scoped lang="scss">
.menu-enter-from,
.menu-leave-to {
  transform: translateX(100%);
}

.menu-enter-active,
.menu-leave-active {
  transition: transform 0.3s;
}

.menu-enter-to,
.menu-leave-from {
  transform: translateX(0);
}

.menu {
  --menu-width: 90vw;
  @include media('>=tablet') {
    --menu-width: 30vw;
  }
  width: var(--menu-width);
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  height: 100dvh;
  z-index: 999;
  background-color: var(--color-background);
  border-left: var(--border-layout);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--layout-margin-h);
  overflow-y: scroll;
  @include hide-scrollbar;
}

.main-navigation {
  position: relative;
}

.space-after {
  margin-bottom: 1.2rem;
  @include media('>=tablet') {
    margin-bottom: 1.8rem;
  }
}

.close-button {
  padding: 0.5rem;

  @include action-state-colors;
}
.main-navigation-list {
  @extend %font-hl3;
  @include media('<tablet') {
    font-size: 1.7em;
  }

  line-height: 1.5;
  text-align: center;
  gap: 1.2rem;

  a {
    padding: 0.5rem;
    @include action-state-colors;
  }

  :deep(.router-link-active) {
    text-decoration: underline;
  }
}

.header {
  display: flex;
  justify-content: space-between;
}
.color-scheme-widget-wrapper {
  margin-top: 3em;
  margin-bottom: 2em;
}
</style>
